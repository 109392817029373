import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import {TikLink} from "../components/helper"

import image404 from "../images/status/404-de.svg";
import logo from "../images/made-by-logo.svg";
import { rootPath } from "../utils/linkResolver";

const NotFoundPage = ({location, data, pageContext}) => {
  const lang = pageContext.langKey;

  return <Layout location={location}  data={data} lang={lang}>
    <SEO title="404: Seite nicht gefunden" />
    <div className="status-404">
      <div className="section-logo">
        <Link className="made-by-logo" to={rootPath({lang: lang})}>
          <img alt="made.by Logo" src={logo} />
        </Link>
      </div>

      <div className="status-404-content">
        <img className="status-404-img" src={image404} alt=""/>
        <div className="status-404-text">
          Sie können zur Homepage zurückkehren. Wenn Sie glauben, dass etwas nicht funktioniert, melden Sie ein Problem.
          <div className="status-404-buttons">
            <TikLink to={rootPath({lang: lang})}>Zur Homepage</TikLink><br />
            <TikLink href="mailto:directsupport@tickaroo.com">Problem melden</TikLink>
          </div>
        </div>
      </div>     

    </div>
  </Layout>
}

NotFoundPage.propTypes = {
  lang: PropTypes.string,
  data: PropTypes.object
}

// THE 'prismic { allAuthors {...}} IS NOT NEEDED, just to prevent validation error ('$lang is defined but never used')
export const query = graphql`
  query($lang: String = "de-de") {
    prismic {
      allAuthorss(lang: $lang) {
        edges {
          node {
            firstname
            lastname
            image
            _linkType
          }
        }
      }
    }

    prismic {
      ...fragmentPrismicLayout
    }
  }
`

export default NotFoundPage
